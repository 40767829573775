import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import PageWrapper from "../components/page/PageWrapper"
import { useForm } from "react-hook-form"
import SubmitButton from "../components/buttons/SubmitButton"
import ShowErrorMessages from "../components/form/ShowErrorMessages"
import { valFuncRequired } from "../util/validationUtil"
import FloatingLabelsInput from "../components/form/input/FloatingLabelInput"
import { useDispatch } from "react-redux"
import { ICreateTestUrlResponse, createTestRecommendationsUrlThunk } from "../store/thunks/testThunks"
import useReduxDispatch from "../store/useReduxDispatch"
import PageTitle from "../components/page/PageTitle"
import StandardButton from "../components/buttons/StandardButton"
import { copyToClipboardText } from "../util/inputUtil"

interface ICreateRecommendationsTestUrlPageProps {}

interface ICreateRecommendationsTestUrlModel {
    apiKey: string
    platformOrderId: string
    email: string
    returnedProductIds: string
}

const CreateRecommendationsTestUrlPage = ({}: ICreateRecommendationsTestUrlPageProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const { register, handleSubmit, formState } = useForm<ICreateRecommendationsTestUrlModel>()
    const [createdUrlResponse, setCreatedUrlResponse] = useState<ICreateTestUrlResponse | undefined>(undefined)

    const onSubmit = async (data: ICreateRecommendationsTestUrlModel) => {
        try {
            setCreatedUrlResponse(undefined)
            const result = await dispatch(
                createTestRecommendationsUrlThunk(data.apiKey, data.platformOrderId, data.email, data.returnedProductIds ?? "")
            )
            setCreatedUrlResponse(result)
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    return (
        <PageWrapper>
            <PageTitle title="Create test URL for recommendations" />
            <form onSubmit={handleSubmit(onSubmit)}>
                <br />
                <FloatingLabelsInput {...register("apiKey", valFuncRequired("API Key is required"))} placeholder="API Key" />
                <br />
                <FloatingLabelsInput
                    {...register("platformOrderId", valFuncRequired("Platform Order ID is required"))}
                    placeholder="Platform Order ID"
                />
                <br />
                <FloatingLabelsInput {...register("email", valFuncRequired("Email is required"))} placeholder="Email of order" />
                <br />
                <FloatingLabelsInput
                    {...register("returnedProductIds")}
                    placeholder="Returned Product IDs  (add more with comma e.g. 32,34,15)"
                />
                <br />

                <ShowErrorMessages errors={formState.errors} />
                <SubmitButton title="Create url" formState={formState} />

                <br />
                <br />
                {createdUrlResponse && (
                    <div>
                        {!createdUrlResponse.isOrderFound && <div>The order could not be found</div>}
                        {createdUrlResponse.isOrderFound && (
                            <div>
                                <div>Order found in webshop: {createdUrlResponse.hostName}</div>
                                <br />
                                <FloatingLabelsInput placeholder="Url" disabled value={createdUrlResponse.url} />
                                <br />
                                <StandardButton
                                    title="Copy to clipboard"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        copyToClipboardText(createdUrlResponse.url)
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}
            </form>
        </PageWrapper>
    )
}

export default CreateRecommendationsTestUrlPage
