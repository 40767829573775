import { getKeys } from "./objectUtils"

export function getUrlSchemeAndHost() {
    return document.location.protocol + "//" + document.location.host
}

export function getQueryValueFromCurrentUrl(queryToLookFor: string): string {
    const pairs = (document.location.search ?? "").replace("?", "").split("&")
    for (let i = 0; i < pairs.length; i++) {
        const values = pairs[i].split("=")
        if (values[0] === queryToLookFor) {
            return values.length > 1 ? values[1] : ""
        }
    }
    return ""
}

export function getCurrentUrlPath(includeSchemeAndHost: boolean, queryParams?: { [key: string]: string }): string {
    const queryString = !queryParams
        ? ""
        : Object.keys(queryParams).reduce((qStr, key) => {
              const part = key + "=" + queryParams[key]
              return qStr.length === 0 ? part : qStr + "&" + part
          }, "")

    return (
        (includeSchemeAndHost ? getUrlSchemeAndHost() : "") +
        document.location.pathname +
        (queryString.length === 0 ? "" : "?" + queryString)
    )
}

export function addQueryParamsToUrl(
    url: string,
    params: { [key: string]: string | number | boolean | undefined },
    removeExistingParams = false
) {
    const query = removeExistingParams
        ? new URLSearchParams()
        : new URLSearchParams(new URL(url.startsWith("http") ? url : "https://dummy.com" + url).search)
    getKeys(params).forEach((key) => {
        query.delete(key + "")
        if (typeof params[key] !== "undefined") {
            query.append(key + "", params[key] + "")
        }
    })
    const updatedQuery = !query.toString() ? "" : "?" + query.toString()
    return url.split(/\?|#/)[0] + updatedQuery
}
