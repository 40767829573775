import { ModalState, modalReducer } from "./reducers/modalReducer"
import { combineReducers } from "redux"
import { configurationReducer, ConfigurationState } from "./reducers/configurationReducer"

export interface StoreState {
    modal: ModalState
    configuration: ConfigurationState
}

export const createRootReducer = () =>
    combineReducers<StoreState>({
        modal: modalReducer,
        configuration: configurationReducer,
    })
