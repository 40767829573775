import React from "react"
import { FieldError, FieldErrors, FieldValues } from "react-hook-form/dist/types"
import { Alert } from "reactstrap"

const ErrorMessage = ({ errorMessage, isLast }: { errorMessage?: string; isLast: boolean }) => {
    return (
        <>
            <span style={{ whiteSpace: "pre-line" }}>{errorMessage}</span>
            {!isLast && <br />}
        </>
    )
}

interface ISingleErrorFromCount {
    errorMessage: string
    errosCount: number
}

interface IShowErrorMessagesProps {
    errors: FieldErrors<FieldValues>
    customError?: string
    singleErrorFromCount?: ISingleErrorFromCount
}
const ShowErrorMessages = ({ errors, customError, singleErrorFromCount }: IShowErrorMessagesProps) => {
    // TODO Custom errors

    const hasFormErrors = !errors || Object.keys(errors).length === 0
    if (hasFormErrors) {
        return null
    }

    const errorMessages = Object.keys(errors)
        .filter((key) => !!errors[key])
        .map((key) => (errors[key] as FieldError).message)
        .filter((error) => error !== undefined)

    if (errorMessages.length === 0) {
        return null
    }

    return (
        <Alert color="danger">
            {!!singleErrorFromCount && singleErrorFromCount.errosCount === errorMessages.length ? (
                <ErrorMessage errorMessage={singleErrorFromCount.errorMessage} isLast={true} />
            ) : (
                errorMessages.map((errMsg, index) => (
                    <ErrorMessage key={index} errorMessage={errMsg} isLast={index === errorMessages.length - 1} />
                ))
            )}
        </Alert>
    )
}

export default ShowErrorMessages
