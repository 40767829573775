import axios, { AxiosRequestConfig, AxiosResponse, AxiosPromise } from "axios"
import { parseServerError } from "./errorService"
import { THttpMethod } from "../type/request/THttpMethod"
import { settings } from "../clientAppSettings"
import { RequestConfig } from "./../store/thunks/serverThunks"
import { logInfo } from "./../util/logUtil"
import { getKeys } from "../util/objectUtils"

export async function serverServiceExecuteRequest<T>(
    method: THttpMethod,
    url: string,
    config?: AxiosRequestConfig,
    requestConfig?: RequestConfig
): Promise<T> {
    const conf = config || {}
    try {
        if (config?.params) {
            config.params = prepareParams(config.params)
        }
        let headers: any = conf.headers
        const contentType = conf.data instanceof FormData ? "multipart/form-data" : "application/json"
        switch (method) {
            case "get":
                break
            case "post":
                headers = {
                    ...conf.headers,
                    "Content-Type": contentType,
                }
                break
            case "put":
                headers = {
                    ...conf.headers,
                    "Content-Type": contentType,
                }
                break
            case "delete":
                headers = {
                    ...conf.headers,
                    "Content-Type": contentType,
                }
                break
            case "patch":
                headers = {
                    ...conf.headers,
                    "Content-Type": contentType,
                }
                break
            default:
                throw new Error("HttpMethod not supported: " + method)
        }
        if (settings.logServerRequests) {
            logInfo(method, url, conf.params, conf.data)
        }
        const response: AxiosResponse<T> = await axios({
            ...conf,
            method,
            headers,
            url,
        })
        if (!response) {
            throw new Error("No response")
        }

        const responseData = response.data as T
        if (settings.logServerResponses) {
            logInfo(method, url, responseData)
            logInfo(JSON.stringify(responseData))
        }
        return responseData
    } catch (error) {
        logInfo("Request failed:", url, conf, error)
        const serverError = parseServerError(error, requestConfig ? requestConfig.defaultErrorMessage : undefined)
        throw serverError
    }
}

// To receive a list in C# when using GET, params need to be of the form: myKey[0]: "val0", myKey[1]: "val1"
function prepareParams(params: { [key: string]: any }) {
    getKeys(params).forEach((k) => {
        if (Array.isArray(params[k])) {
            const arr = params[k] as any[]
            delete params[k]
            arr.forEach((v, i) => {
                params[`${k}[${i}]`] = v
            })
        }
    })

    return params
}
