import React from "react"

interface IProps {
    id?: string
    className?: string
}
const TestPageIframeContainer = ({ id }: IProps) => {
    return <div id={id} style={{ marginTop: 20 }}></div>
}
export default TestPageIframeContainer
