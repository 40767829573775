import React from "react"
import { mount, route } from "navi"
import { INavigationContext } from "./INavigationContext"
import RecommendationOrchestratorTestPage from "../pages/RecommendationOrchestratorTestPage"
import CreateRecommendationsTestUrlPage from "../pages/CreateRecommendationsTestUrlPage"

export const mainRoutes = mount<INavigationContext>({
    "/recommendationsTest": route({
        view: <RecommendationOrchestratorTestPage />,
    }),
    "/createTestUrl": route({
        view: <CreateRecommendationsTestUrlPage />,
    }),
})
