import React, { useEffect, useRef, useState } from "react"
import { Input, InputProps, Alert, InputGroup } from "reactstrap"
import "./FloatingLabelInput.scss"
import cn from "classnames"
import $ from "jquery"

export interface IFloatingLabelsInputProps extends InputProps {
    placeholder?: string
    extraLabel?: string
    textareaNotExtendable?: boolean
    autoCompleteOff?: boolean
    disableHintAfter?: number
    hintInsideInput?: string
    allowDecimals?: boolean
    containerClass?: string
}

const selectors = {
    targetClass: "placeholder-hidden",
}

const handleValueChange = ($element: JQuery<HTMLElement>) => {
    if (!$element.hasClass(selectors.targetClass) && $element.val()) {
        $element.addClass(selectors.targetClass)
        $element.parent().find("span").addClass(selectors.targetClass)
    }
}

const applyListeners = ($element: JQuery<HTMLElement>) => {
    const handleGotFocus = () => {
        if (!$element.hasClass(selectors.targetClass)) {
            $element.addClass(selectors.targetClass)
            $element.parent().find("span.floating-label").addClass(selectors.targetClass)
        }
    }
    const handleLostFocus = () => {
        if ($element.hasClass(selectors.targetClass) && !$element.val()) {
            $element.removeClass(selectors.targetClass)
            $element.parent().find("span.floating-label").removeClass(selectors.targetClass)
        }
    }

    handleValueChange($element)

    $element.on("focusout", () => {
        handleLostFocus()
    })
    $element.on("focusin", () => {
        handleGotFocus()
    })
    $element.on("keydown", () => {
        handleGotFocus()
    })
    $element.on("input", () => {
        handleValueChange($element)
    })
}

const applyFloatingLabels = (node: HTMLDivElement) => {
    const inputs = $(node).find("input")
    inputs.each((index, element) => {
        applyListeners($(element))
    })

    const textareasExtendable = $(node).find("textarea.extendable")
    textareasExtendable.each((index, element) => {
        applyListeners($(element))
        element.addEventListener(
            "input",
            function () {
                this.style.overflow = "hidden"
                this.style.height = "0"
                this.style.height = this.scrollHeight + "px"
            },
            false
        )
    })

    const textareasUnextendable = $(node).find("textarea.unextendable")
    textareasUnextendable.each((index, element) => {
        applyListeners($(element))
    })
}

const FloatingLabelsInput: React.FC<IFloatingLabelsInputProps> = React.forwardRef((compProps, ref) => {
    // Moved deconstructor here, because of using React.forwardRef and eslint
    const {
        placeholder,
        extraLabel,
        bottomHint,
        type,
        containerClass,
        textareaNotExtendable,
        onFocus,
        onBlur,
        onChange,
        autoCompleteOff,
        disableHintAfter,
        hintInsideInput,
        allowDecimals,
        onKeyDown,
        ...otherProps
    } = compProps
    const handle = useRef<HTMLDivElement | null | undefined>()
    const [initialized, setInitialized] = useState(false)
    const [showHint, setHint] = useState(false)
    const [showHintInsideInput, setShowHintInsideInput] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const spanHandle = useRef<HTMLSpanElement | null | undefined>()
    useEffect(() => {
        if (handle.current) {
            applyFloatingLabels(handle.current)

            setTimeout(() => {
                // First animate placeholders after the initial setup
                // If input starts with a value, placeholder should float without an animation
                setInitialized(true)
            }, 100)
        }
    }, [])

    if (handle.current) {
        handleValueChange($(handle.current).find("input").eq(0))
    }

    return (
        <div ref={(ref) => (handle.current = ref)} className={cn("form-label-group mb-0", containerClass)}>
            {type === "password" ? (
                <InputGroup>
                    <Input
                        {...otherProps}
                        innerRef={ref as any}
                        autoComplete={autoCompleteOff ? "new-password" : ""}
                        className="password-input"
                        type={showPassword ? "text" : "password"}
                        onFocus={onFocus ? onFocus : () => setHint(true)}
                        onBlur={onBlur ? onBlur : () => setHint(false)}
                    />
                    <span
                        className={cn(initialized && "initialized", "floating-label")}
                        ref={(ref) => (spanHandle.current = ref)}
                        data-value={placeholder}
                    ></span>
                    <div className="show-password" onClick={() => setShowPassword(!showPassword)}>
                        <span
                            className={cn("show-password-text", "no-select", "icon", showPassword ? "icon-hide" : "icon-show")}
                        ></span>
                    </div>
                </InputGroup>
            ) : (
                <InputGroup>
                    <Input
                        {...otherProps}
                        innerRef={ref as any}
                        autoComplete={autoCompleteOff ? "new-password" : ""}
                        className={cn(textareaNotExtendable ? "unextendable" : "extendable")}
                        type={type || "text"}
                        onKeyDown={(e) => {
                            if (onKeyDown) {
                                onKeyDown(e)
                            }

                            if (type === "number" && (e.key == "e" || e.key == "E" || (!allowDecimals && e.key == "."))) {
                                e.preventDefault()
                            }
                        }}
                        onFocus={onFocus ? onFocus : () => setHint(true)}
                        onBlur={onBlur ? onBlur : () => setHint(false)}
                        onChange={(e) => {
                            if (disableHintAfter) {
                                e.target.value.length > disableHintAfter ? setHint(false) : setHint(true)
                            }
                            if (onChange) {
                                onChange(e)
                            }
                        }}
                    />
                    {hintInsideInput && (
                        <span className="hint-inside-input">
                            <span
                                className="icon icon-hint"
                                onClick={() => setShowHintInsideInput(!showHintInsideInput)}
                                onMouseOut={() => {
                                    setShowHintInsideInput(false)
                                }}
                                onTouchStart={() => {
                                    setShowHintInsideInput(true)
                                }}
                                onTouchEnd={() => {
                                    setShowHintInsideInput(false)
                                }}
                            ></span>
                            <span className={cn("text-hint", { "d-none": !showHintInsideInput })}>{hintInsideInput}</span>
                        </span>
                    )}
                    <span
                        className={cn(initialized && "initialized", "floating-label")}
                        ref={(ref) => (spanHandle.current = ref)}
                        data-value={placeholder}
                    ></span>
                </InputGroup>
            )}
            {extraLabel && <div className="extra-label">{extraLabel}</div>}
            <Hint hintText={bottomHint} showElement={showHint}></Hint>
        </div>
    )
})

const Hint = ({ hintText, showElement }: { hintText?: string; showElement: boolean }) => {
    return (
        <>
            {hintText && (
                <Alert isOpen={showElement} className="alert-f2" color="dark">
                    <span className={cn("icon page-icon icon-exclamation-mark")}></span> {hintText}
                </Alert>
            )}
        </>
    )
}

// Because of using React.forwardRef and eslint
FloatingLabelsInput.displayName = "FloatingLabelsInput"
export default FloatingLabelsInput
