import { ThunkAction } from "redux-thunk"
import { StoreState } from "../StoreState"
import { AnyAction } from "redux"
import { requestThunk } from "./serverThunks"
import { endpoints } from "../../config/endpoints"

export interface ICreateTestUrlResponse {
    isOrderFound: boolean
    url: string
    hostName: string
}

export const createTestRecommendationsUrlThunk =
    (
        apiKey: string,
        platformOrderId: string,
        email: string,
        returnedProductIds: string
    ): ThunkAction<Promise<ICreateTestUrlResponse>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const response = await dispatch(
            requestThunk<ICreateTestUrlResponse>(endpoints.Self.createTestRecommendationUrl, {
                data: {
                    apiKey,
                    platformOrderId,
                    email,
                    returnedProductIds,
                },
            })
        )

        return response
    }
