import jQuery from "jquery"
import { IRecommendationPageOrchestratorConfigComponent } from "../orchestrations/recommendationPageOrchestrator"
import { EIframeType } from "../types/EIframeType"
import { ILookupOrderConfig } from "../types/ILookupOrderConfig"
import { IReturnedProductConfig } from "../types/IReturnedProductConfig"
import { encode, isAlreadyEncoded } from "../utils/encodeUtils"

export interface IDefaultIframeEvent {
    fromId?: string
    toId?: string
    type: string
    data?: any
    passToIframes?: string[]
}

export interface IIframeConfig {
    types: EIframeType[]
    modalId?: string
    cartId?: string
    containerId: string
}

export interface IIframeAdded extends IIframeConfig {
    id: string
    elemId: string
    getElem: () => JQuery<HTMLIFrameElement>
}

const iframes: IIframeAdded[] = []

export function addIframe(
    config: IIframeConfig,
    iframeId: string,
    controllerIframeId: string,
    orderConfig: ILookupOrderConfig,
    returnedProducts: IReturnedProductConfig[]
) {
    const iv = isAlreadyEncoded(orderConfig.iv) ? orderConfig.iv : encode(orderConfig.iv)
    const encryptedData = isAlreadyEncoded(orderConfig.encryptedData)
        ? orderConfig.encryptedData
        : encode(orderConfig.encryptedData)

    const $container = jQuery("#" + config.containerId)
    const iframeElemId = "swiipe-" + iframeId + "-iframe"

    const component = config.types.map((type) => (type === "recommendations" ? "productcarousel" : type))

    // TODO: This exposes package.json - find a better solution
    const version = require("../package.json").version

    const returnedProductsParam =
        returnedProducts.length > 0 ? "&returnedProducts=" + window.btoa(JSON.stringify(returnedProducts)) : ""

    const defaultHeight = config.types.includes("recommendations") ? 550 : 0

    $container.append(
        `<iframe 
            id="${iframeElemId}"
            src="${configuredEndpoint}/components?c=${component}&id=${iframeId}${
            config.modalId ? "&modalId=" + config.modalId : ""
        }${config.cartId ? "&cartId=" + config.cartId : ""}&controllerId=${controllerIframeId}&integratorConnectionId=${
            orderConfig.connectionId
        }&encryptedOrderData=${encryptedData}&encryptedOrderIv=${iv}&version=${version}&parentIsEcommercePortal=${
            window.location.host.endsWith("ecommerce.swiipe.com") ? "true" : "false"
        }${returnedProductsParam}"
            width="100%" height="${defaultHeight}" frameBorder="0" style="display: block; background-color: transparent;">
        </iframe>`
    )

    const $elem = jQuery("#" + iframeElemId)
    if (config.types.includes("modal")) {
        $elem.css("position", "fixed")
        $elem.css("top", 0)
        $elem.css("left", 0)
        $elem.css("display", "none")
    }

    iframes.push({
        id: iframeId,
        elemId: iframeElemId,
        getElem: () => jQuery("#" + iframeElemId),
        ...config,
    })
}

export function calculateIframeId(
    component: IRecommendationPageOrchestratorConfigComponent,
    components: IRecommendationPageOrchestratorConfigComponent[]
) {
    const getTypeName = (c: IRecommendationPageOrchestratorConfigComponent) => c.types.join("_")
    const allWithSameTypes = components.filter((c) => getTypeName(c) === getTypeName(component))
    const index = allWithSameTypes.indexOf(component)
    return index === 0 ? getTypeName(component) : getTypeName(component) + (index + 1)
}

export function calculateControllerIframeId(components: IRecommendationPageOrchestratorConfigComponent[]) {
    const controllerComp = components.find((c) => c.types.includes("recommendations") || c.types.includes("returnedproducts"))
    if (!controllerComp) {
        throw "At least one component with type recommendations must be configured"
    }
    return calculateIframeId(controllerComp, components)
}

export function setIframeSize(
    $iframe: JQuery<HTMLIFrameElement> | undefined,
    width: number | string | undefined,
    height: number | string | undefined,
    display?: "none" | "block"
) {
    if (!$iframe) {
        return
    }
    if (width !== undefined && width !== null && width !== "") {
        $iframe.attr("width", width)
        $iframe.width(width)
        $iframe.css({ width: width })
    }
    if (height !== undefined && height !== null && height !== "") {
        $iframe.attr("height", height)
        $iframe.height(height)
        $iframe.css({ height: height })
    }
    if (display) {
        $iframe.css("display", display)
    }
}

export const getIframes = (iframeIds?: string[]) => {
    return iframes.filter((iframe) => !iframeIds || iframeIds.includes("*") || iframeIds.includes(iframe.id))
}

let configuredEndpoint = "https://ecommerce.swiipe.com"
export function setIframeEndpoint(endpoint?: string, isSandbox?: boolean) {
    if (endpoint) {
        configuredEndpoint = endpoint
        return
    }
    configuredEndpoint = isSandbox ? "https://sandbox-ecommerce.swiipe.com" : "https://ecommerce.swiipe.com"
}
