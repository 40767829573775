import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import "./RecommendationOrchestratorTestPage.scss"
import PageWrapper from "../components/page/PageWrapper"
import { initRecommendationPageOrchestrator } from "../orchestrator/orchestrations/recommendationPageOrchestrator"
import TestPageIframeContainer from "../components/helpers/TestPageIframeContainer"
import { getQueryValueFromCurrentUrl } from "../util/urlUtil"
import { useSelector } from "react-redux"
import { configurationSelectors } from "../store/reducers/configurationReducer"
import { StoreState } from "../store/StoreState"
import { IReturnedProductConfig } from "../orchestrator/types/IReturnedProductConfig"

interface IRecommendationOrchestratorTestPageProps {}

const RecommendationOrchestratorTestPage = ({}: IRecommendationOrchestratorTestPageProps) => {
    const { t } = useTranslation()

    const returnedProductIds = useMemo(() => getQueryValueFromCurrentUrl("returnedProductIds"), [])
    const returnedSKUs = useMemo(() => getQueryValueFromCurrentUrl("returnedSKUs"), [])
    const connectionId = useMemo(() => getQueryValueFromCurrentUrl("connectionId"), [])
    const data = useMemo(() => getQueryValueFromCurrentUrl("data"), [])
    const iv = useMemo(() => getQueryValueFromCurrentUrl("iv"), [])
    const initialized = useSelector(configurationSelectors.initialized)
    const endpoint = useSelector((state: StoreState) => configurationSelectors.endpointConfig(state, "Self"))
    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        if (!initialized || !endpoint) {
            return
        }
        initRecommendationPageOrchestrator({
            onStatusChange: (status) => {
                setIsReady(status.status === "ready")
            },
            returnedProducts: [
                ...decodeURIComponent(returnedProductIds || "")
                    .split(",")
                    .map((str) => str.trim())
                    .filter((str) => !!str)
                    .map((id) => {
                        const c: IReturnedProductConfig = { qty: 1, productId: id }
                        return c
                    }),
                ...decodeURIComponent(returnedSKUs || "")
                    .split(",")
                    .map((str) => str.trim())
                    .filter((str) => !!str)
                    .map((sku) => {
                        const c: IReturnedProductConfig = { qty: 1, sku: sku }
                        return c
                    }),
            ],
            iframeEndpoint: {
                endpoint: endpoint,
            },
            order: {
                connectionId,
                encryptedData: data,
                iv,
            },
            components: [
                {
                    containerId: "recommendation-orchestrator-test-page__container-part1",
                    types: ["returnedproducts", "recommendations"],
                },
                // {
                //     containerId: "recommendation-orchestrator-test-page__container-part1",
                //     types: ["recommendations"],
                // },
                {
                    containerId: "recommendation-orchestrator-test-page__container-part2",
                    types: ["recommendations"],
                },
                // {
                //     containerId: "recommendation-orchestrator-test-page__container-part2",
                //     types: ["cart"],
                // },
                {
                    containerId: "recommendation-orchestrator-test-page__container-part2",
                    types: ["modal"],
                },
            ],
        })
    }, [initialized, endpoint])

    return (
        <PageWrapper>
            <div style={{ backgroundColor: isReady ? "transparent" : "transparent" }}>
                <div style={{ textAlign: "center", marginTop: 30, marginBottom: 30 }}>
                    <h1>Page that needs recommendations</h1>
                    <h4>Not necessarily on the webshop itself</h4>
                </div>
                <TestPageIframeContainer id="recommendation-orchestrator-test-page__container-part1" />
                <div>
                    <h1 style={{ fontSize: 30 }}>Order tracking</h1>
                    <div>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Vel fringilla est ullamcorper eget nulla facilisi. Scelerisque viverra mauris in
                            aliquam. Tellus elementum sagittis vitae et leo duis ut diam quam. Augue neque gravida in fermentum et
                            sollicitudin ac orci phasellus. Morbi enim nunc faucibus a pellentesque sit amet porttitor eget.
                            Sollicitudin aliquam ultrices sagittis orci. Porttitor lacus luctus accumsan tortor posuere ac ut
                            consequat. Pellentesque habitant morbi tristique senectus et netus et. Ultrices mi tempus imperdiet
                            nulla malesuada pellentesque elit. Senectus et netus et malesuada fames ac turpis egestas. Lorem dolor
                            sed viverra ipsum nunc aliquet bibendum enim. Tellus in hac habitasse platea. Maecenas ultricies mi
                            eget mauris pharetra et. Massa sapien faucibus et molestie ac feugiat. Consequat nisl vel pretium
                            lectus quam id leo in vitae. Eleifend mi in nulla posuere sollicitudin aliquam ultrices. Neque gravida
                            in fermentum et sollicitudin. Vulputate mi sit amet mauris. Dignissim diam quis enim lobortis
                            scelerisque fermentum.
                        </p>

                        <p>
                            Consectetur purus ut faucibus pulvinar elementum integer. At tellus at urna condimentum mattis
                            pellentesque id nibh. Convallis aenean et tortor at risus viverra adipiscing at. Sagittis orci a
                            scelerisque purus semper. Facilisi etiam dignissim diam quis enim lobortis scelerisque. Sed risus
                            pretium quam vulputate. Magna fringilla urna porttitor rhoncus. Massa id neque aliquam vestibulum
                            morbi blandit cursus risus at. Eleifend donec pretium vulputate sapien nec sagittis aliquam malesuada
                            bibendum. Quis vel eros donec ac. Fringilla est ullamcorper eget nulla facilisi. Ullamcorper eget
                            nulla facilisi etiam dignissim diam quis enim lobortis. Massa ultricies mi quis hendrerit dolor.
                        </p>

                        <p>
                            Tortor id aliquet lectus proin nibh nisl condimentum. Sem et tortor consequat id porta nibh. Faucibus
                            pulvinar elementum integer enim neque volutpat ac tincidunt. Nibh sed pulvinar proin gravida hendrerit
                            lectus a. Sit amet nisl suscipit adipiscing bibendum est ultricies integer quis. Sapien eget mi proin
                            sed. Est velit egestas dui id ornare arcu. Volutpat ac tincidunt vitae semper quis lectus nulla at
                            volutpat. Sed risus ultricies tristique nulla aliquet enim. Odio ut enim blandit volutpat maecenas
                            volutpat. Arcu dictum varius duis at consectetur lorem donec massa sapien. Mauris vitae ultricies leo
                            integer malesuada nunc vel.
                        </p>

                        <p>
                            Sed arcu non odio euismod lacinia at quis risus. A arcu cursus vitae congue mauris. Et pharetra
                            pharetra massa massa ultricies mi quis hendrerit dolor. Nisi quis eleifend quam adipiscing vitae proin
                            sagittis nisl. Mauris nunc congue nisi vitae suscipit tellus mauris a diam. Tincidunt lobortis feugiat
                            vivamus at augue eget. Commodo ullamcorper a lacus vestibulum sed arcu. Purus in mollis nunc sed id
                            semper. Arcu dictum varius duis at consectetur lorem. Sed viverra tellus in hac. Et molestie ac
                            feugiat sed lectus vestibulum. Dignissim cras tincidunt lobortis feugiat vivamus. Neque vitae tempus
                            quam pellentesque nec nam aliquam. Suspendisse interdum consectetur libero id faucibus nisl. Nulla
                            posuere sollicitudin aliquam ultrices sagittis orci a scelerisque. Tincidunt arcu non sodales neque
                            sodales ut etiam sit amet. Potenti nullam ac tortor vitae purus faucibus.
                        </p>

                        <p>
                            Eu volutpat odio facilisis mauris sit amet massa vitae tortor. Sed blandit libero volutpat sed cras
                            ornare arcu dui. Viverra adipiscing at in tellus integer feugiat scelerisque. Varius quam quisque id
                            diam vel quam elementum pulvinar etiam. Varius quam quisque id diam vel quam elementum pulvinar.
                            Semper viverra nam libero justo. Rhoncus mattis rhoncus urna neque. Adipiscing at in tellus integer
                            feugiat scelerisque varius morbi enim. Neque egestas congue quisque egestas diam. Massa sed elementum
                            tempus egestas sed sed. Est ante in nibh mauris cursus mattis molestie a iaculis. Suspendisse interdum
                            consectetur libero id. Consectetur a erat nam at. Fermentum et sollicitudin ac orci phasellus egestas
                            tellus rutrum tellus. Ante in nibh mauris cursus mattis molestie a. Consequat semper viverra nam
                            libero justo laoreet. Egestas sed sed risus pretium. Suspendisse in est ante in nibh mauris. Est
                            ultricies integer quis auctor. Fermentum iaculis eu non diam phasellus vestibulum.
                        </p>
                    </div>
                </div>
                <TestPageIframeContainer id="recommendation-orchestrator-test-page__container-part2" />
                <div>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Vel fringilla est ullamcorper eget nulla facilisi. Scelerisque viverra mauris in
                        aliquam. Tellus elementum sagittis vitae et leo duis ut diam quam. Augue neque gravida in fermentum et
                        sollicitudin ac orci phasellus. Morbi enim nunc faucibus a pellentesque sit amet porttitor eget.
                        Sollicitudin aliquam ultrices sagittis orci. Porttitor lacus luctus accumsan tortor posuere ac ut
                        consequat. Pellentesque habitant morbi tristique senectus et netus et. Ultrices mi tempus imperdiet nulla
                        malesuada pellentesque elit. Senectus et netus et malesuada fames ac turpis egestas. Lorem dolor sed
                        viverra ipsum nunc aliquet bibendum enim. Tellus in hac habitasse platea. Maecenas ultricies mi eget
                        mauris pharetra et. Massa sapien faucibus et molestie ac feugiat. Consequat nisl vel pretium lectus quam
                        id leo in vitae. Eleifend mi in nulla posuere sollicitudin aliquam ultrices. Neque gravida in fermentum et
                        sollicitudin. Vulputate mi sit amet mauris. Dignissim diam quis enim lobortis scelerisque fermentum.
                    </p>

                    <p>
                        Consectetur purus ut faucibus pulvinar elementum integer. At tellus at urna condimentum mattis
                        pellentesque id nibh. Convallis aenean et tortor at risus viverra adipiscing at. Sagittis orci a
                        scelerisque purus semper. Facilisi etiam dignissim diam quis enim lobortis scelerisque. Sed risus pretium
                        quam vulputate. Magna fringilla urna porttitor rhoncus. Massa id neque aliquam vestibulum morbi blandit
                        cursus risus at. Eleifend donec pretium vulputate sapien nec sagittis aliquam malesuada bibendum. Quis vel
                        eros donec ac. Fringilla est ullamcorper eget nulla facilisi. Ullamcorper eget nulla facilisi etiam
                        dignissim diam quis enim lobortis. Massa ultricies mi quis hendrerit dolor.
                    </p>

                    <p>
                        Tortor id aliquet lectus proin nibh nisl condimentum. Sem et tortor consequat id porta nibh. Faucibus
                        pulvinar elementum integer enim neque volutpat ac tincidunt. Nibh sed pulvinar proin gravida hendrerit
                        lectus a. Sit amet nisl suscipit adipiscing bibendum est ultricies integer quis. Sapien eget mi proin sed.
                        Est velit egestas dui id ornare arcu. Volutpat ac tincidunt vitae semper quis lectus nulla at volutpat.
                        Sed risus ultricies tristique nulla aliquet enim. Odio ut enim blandit volutpat maecenas volutpat. Arcu
                        dictum varius duis at consectetur lorem donec massa sapien. Mauris vitae ultricies leo integer malesuada
                        nunc vel.
                    </p>

                    <p>
                        Sed arcu non odio euismod lacinia at quis risus. A arcu cursus vitae congue mauris. Et pharetra pharetra
                        massa massa ultricies mi quis hendrerit dolor. Nisi quis eleifend quam adipiscing vitae proin sagittis
                        nisl. Mauris nunc congue nisi vitae suscipit tellus mauris a diam. Tincidunt lobortis feugiat vivamus at
                        augue eget. Commodo ullamcorper a lacus vestibulum sed arcu. Purus in mollis nunc sed id semper. Arcu
                        dictum varius duis at consectetur lorem. Sed viverra tellus in hac. Et molestie ac feugiat sed lectus
                        vestibulum. Dignissim cras tincidunt lobortis feugiat vivamus. Neque vitae tempus quam pellentesque nec
                        nam aliquam. Suspendisse interdum consectetur libero id faucibus nisl. Nulla posuere sollicitudin aliquam
                        ultrices sagittis orci a scelerisque. Tincidunt arcu non sodales neque sodales ut etiam sit amet. Potenti
                        nullam ac tortor vitae purus faucibus.
                    </p>

                    <p>
                        Eu volutpat odio facilisis mauris sit amet massa vitae tortor. Sed blandit libero volutpat sed cras ornare
                        arcu dui. Viverra adipiscing at in tellus integer feugiat scelerisque. Varius quam quisque id diam vel
                        quam elementum pulvinar etiam. Varius quam quisque id diam vel quam elementum pulvinar. Semper viverra nam
                        libero justo. Rhoncus mattis rhoncus urna neque. Adipiscing at in tellus integer feugiat scelerisque
                        varius morbi enim. Neque egestas congue quisque egestas diam. Massa sed elementum tempus egestas sed sed.
                        Est ante in nibh mauris cursus mattis molestie a iaculis. Suspendisse interdum consectetur libero id.
                        Consectetur a erat nam at. Fermentum et sollicitudin ac orci phasellus egestas tellus rutrum tellus. Ante
                        in nibh mauris cursus mattis molestie a. Consequat semper viverra nam libero justo laoreet. Egestas sed
                        sed risus pretium. Suspendisse in est ante in nibh mauris. Est ultricies integer quis auctor. Fermentum
                        iaculis eu non diam phasellus vestibulum.
                    </p>
                </div>
            </div>
        </PageWrapper>
    )
}

export default RecommendationOrchestratorTestPage
