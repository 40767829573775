import React from "react"
import SpinnerContainer from "../loaders/SpinnerContainer"
import cn from "classnames"
import "./SubmitButton.scss"
import StandardButton, { IStandardButtonProps } from "./StandardButton"
import { FormState } from "react-hook-form"

interface ISubmitButtonProps extends IStandardButtonProps {
    formState: FormState<any>
    containerClass?: string
    floatRight?: boolean
    disableSpinner?: boolean
}

const SubmitButton = ({
    formState,
    disabled,
    className,
    floatRight,
    children,
    containerClass,
    disableSpinner,
    ...otherProps
}: ISubmitButtonProps) => {
    const { isValidating, isSubmitting } = formState

    return (
        <SpinnerContainer
            showSpinner={formState.isSubmitting && !disableSpinner}
            className={cn({ "float-right": floatRight }, containerClass)}
        >
            <StandardButton
                {...otherProps}
                className={cn("swiipe-submit-btn", className)}
                disabled={isValidating || isSubmitting || disabled}
                type="submit"
                variant="primary"
            >
                {children}
            </StandardButton>
        </SpinnerContainer>
    )
}

export default SubmitButton
